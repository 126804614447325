import React from "react";
import "./slotbooking.css";
import SlotBooking from '../../components/slotbooking/slotbooking.js';

function SlotBookingPage() {
    return(
        <div>
            <SlotBooking/>
        </div>
    )
}

export default SlotBookingPage;