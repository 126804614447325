import React from "react";
import MeetTeamHeading from "./meetteamheading";
import MeetTeamContent from "./meetteamcontent";

export default function MeetTeam () {
    return(
        <div>
            <MeetTeamHeading/>
            <MeetTeamContent/>
        </div>
    )
}