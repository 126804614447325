import React from "react";
import RoadToAPL from "../../components/roadtoapl/roadtoapl";

function RoadToAPLPage() {
    return(
        <div>
            <RoadToAPL/>
        </div>
    )
}

export default RoadToAPLPage;